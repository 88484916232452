.mui-input-outlined {
  position: relative;
  width: 98%;
}

.mui-input-outlined input {
  width: 100%;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  font-family: inherit;
  transition: border-color 0.3s;
  box-sizing: border-box;
}

.mui-input-outlined input:focus {
  outline: none;
  border-color: #2C2799;
  border-width: 2px;
}

.mui-input-outlined input::placeholder {
  color: #9e9e9e;
}

.mui-input-outlined label {
  position: absolute;
  top: 45%;
  left: 14px;
  transform: translateY(-50%);
  color: #9e9e9e;
  font-size: 1.25rem;
  font-family: inherit;
  pointer-events: none;
  transition: top 0.2s, font-size 0.2s, color 0.2s;
  background-color: white;
  padding: 0 5px;
}

.mui-input-outlined input:focus ~ label,
.mui-input-outlined input:not(:placeholder-shown) ~ label {
  top: 0;
  font-size: 1rem;
  color: #2C2799;
}
